<template>
  <div class="lottery-trend-zodiac">
    <div class="header">
      <div class="title-box">
        <p class="title">和值走势</p>
        <intro-default cont=""></intro-default>
      </div>
      <div class="search-box">
        <div class="switch_fn_bt" @click="rlt_show">{{ is_rlt ? '隐藏' : '展开' }}结果</div>
        <select-user type="1" @change="page_change"></select-user>
      </div>
    </div>
    <div class="lz_cont">
      <div class="table" id="table">
        <!-- :width="tableWidth" -->
        <table cellpadding="0" cellspacing="0" class="table-data" width="100%" ref="table">
          <thead>
            <tr>
              <th scope="col" width="180" rowspan="2" colspan="1">开奖时间</th>
              <th scope="col" width="140" rowspan="2" colspan="1">期号</th>
              <th v-if="is_rlt" scope="col" rowspan="2" colspan="1" width="240">开奖号码</th>
              <th scope="col" rowspan="1" colspan="16">总和</th>
            </tr>
            <tr>
              <th rowspan="1" colspan="1" v-for="item in 16" :key="item">{{ item + 2 + '分' }}</th>
            </tr>
          </thead>
          <tbody>
            <tr height="37" v-for="row in tableData" :key="row.expect">
              <td>{{ row.opentime }}</td>
              <td>{{ row.expect }}</td>
              <td v-if="is_rlt">
                <div class="opencode">
                  <span v-for="(code, i) in row.opencode" :key="`${code}-${i}`">{{ code }}</span>
                </div>
              </td>
              <td v-for="(item, i) in row.list" :key="`${item}-${i}`">
                <span v-if="typeof item === 'string'" class="hot" ref="hots">{{ item }}</span>
                <template v-else>{{ item }}</template>
              </td>
            </tr>
          </tbody>
        </table>
        <trend-line-canvas v-for="obj in lineObjects" :key="obj.id" :width="obj.width" :height="obj.height"
          :start="obj.start" :end="obj.end" :color="obj.color" :line-width="obj.lineWidth" :top="obj.top"
          :left="obj.left"></trend-line-canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Loading } from 'element-ui'
import TrendLineCanvas from '@components/pc/TrendLineCanvas'
import randomString from '@plugins/random'
import moment from 'moment'

export default {
  name: 'SumTrend',
  props: {
    code: String
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [TrendLineCanvas.name]: TrendLineCanvas
  },
  data() {
    return {
      per_page: 10,
      is_rlt: true,
      data: [],
      loading: null,
      lineObjects: []
    }
  },
  computed: {
    rankNames() {
      const arr = this.get_type_name(this.code)
      return arr
    },
    tableData() {
      const arr = []
      for (const { opentime, expect, list, opencode } of this.data) {
        arr.push({
          opentime: this.normal_time(opentime),
          expect,
          list: list[0],
          opencode
        })
      }
      return arr
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.per_page = 10
      this.requestGetData()
    }
  },
  mounted() {
    this.requestGetData()
  },
  methods: {
    normal_time(date) {
      return moment(parseInt(date) * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    page_change(value) {
      this.per_page = value
      this.requestGetData()
    },
    rlt_show() {
      this.is_rlt = !this.is_rlt
      this.$nextTick(() => {
        this.drawLine()
      })
    },
    refresh() {
      this.requestGetData()
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      const arr = ['table-cell']
      if ((row.big === '大' && columnIndex === 0) ||
        (row.small === '小' && columnIndex === 1) ||
        (row.single === '单' && columnIndex === 2) ||
        (row.double === '双' && columnIndex === 3) ||
        (row.eq === '和' && columnIndex === 4)) {
        arr.push('active')
      }
      return arr.join(' ')
    },
    requestGetData() {
      this.$api.sumTrend(this.code, this.per_page).then((res) => {
        this.data = res.data
        this.hideTableLoading()
        this.$nextTick(() => {
          this.drawLine()
        })
      }).catch((err) => {
        this.hideTableLoading()
        this.$message.error(err)
      })
    },
    drawLine() {
      this.lineObjects = []

      const lineWidth = 1

      const hots = this.$refs.hots.sort((a, b) => {
        return a.getBoundingClientRect().y - b.getBoundingClientRect().y
      })

      const tableRect = this.$refs.table.getBoundingClientRect()

      var lastCenter = null

      for (let i = 0; i < hots.length; i++) {
        const hot = hots[i]
        const hotRect = hot.getBoundingClientRect()
        const center = {
          x: hotRect.x - tableRect.x + hotRect.width / 2,
          y: hotRect.y - tableRect.y + hotRect.height / 2
        }

        if (!lastCenter) {
          lastCenter = center
          continue
        }

        var obj = {
          lineWidth,
          color: '#1D8DDE',
          id: randomString(7)
        }

        const canvasWidth = Math.abs(lastCenter.x - center.x)
        const canvasHeight = Math.abs(lastCenter.y - center.y)

        obj.width = canvasWidth
        obj.height = canvasHeight

        if (lastCenter.x > center.x) {
          obj.start = {
            x: canvasWidth,
            y: 0
          }
          obj.end = {
            x: 0,
            y: canvasHeight
          }

          obj.left = center.x
          obj.top = lastCenter.y
        } else if (lastCenter.x < center.x) {
          obj.start = {
            x: 0,
            y: 0
          }
          obj.end = {
            x: canvasWidth,
            y: canvasHeight
          }

          obj.left = lastCenter.x
          obj.top = lastCenter.y
        } else {
          obj.start = {
            x: lineWidth / 2,
            y: 0
          }
          obj.end = {
            x: lineWidth / 2,
            y: canvasHeight
          }

          obj.left = (lastCenter.x - lineWidth / 2)
          obj.top = lastCenter.y
          obj.width = lineWidth
        }

        this.lineObjects.push(obj)

        lastCenter = center
      }
    },
    showTableLoading() {
      if (this.loading) return
      this.loading = Loading.service({
        // target: '#table',
        fullscreen: true,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading() {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';

.lottery-trend-zodiac {
  .header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 49px;

      >.title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }

    >.search-box {
      display: flex;
      align-items: center;

      >.title {
        margin-right: 15px;
        font-size: 14px;
        color: #222;
      }

      >.icon-search {
        margin-left: 10px;
        width: 48px;
        height: 24px;
        display: block;
      }
    }
  }

  .lz_cont {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .types {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    margin-bottom: 30px;

    >li {
      cursor: pointer;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      color: #858586;
      background-color: #E3E3E5;
      width: 70px;
      height: 24px;
      border-radius: 4px;

      &.active {
        color: #FFFFFF;
        background-color: #FA3E3E;
      }

      &+li {
        margin-left: 10px;
      }
    }
  }


  .table {
    position: relative;

    >.table-data {
      table-layout: fixed;
      word-break: break-all;
      word-wrap: break-word;
      border-collapse: separate;
      border: 1px solid #d6d6d6;
      border-right: none;
      border-bottom: none;

      .el-table thead {
        color: #909399;
        font-weight: bold;
      }

      td,
      th {
        min-width: 0;
        box-sizing: border-box;
        text-overflow: ellipsis;
        vertical-align: middle;
        position: relative;
        text-align: center;
        border-bottom: 1px solid #d6d6d6;
        border-right: 1px solid #d6d6d6;
        font-size: 14px;
      }

      th {
        height: 40px;
        font-weight: bold;
        background-color: #f9f9f9;
        color: rgba(0, 0, 0, 0.5);
      }

      td {
        color: rgba(0, 0, 0, 0.8);
      }
    }

    .opencode {
      color: #fa3e3e;

      span+span {
        margin-left: 5px;
      }
    }

    .hot {
      position: relative;
      z-index: 100;
      display: inline-block;
      color: white;
      background: #07A6F8;
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 12px;
      text-align: center;
      border-radius: 50%;
    }
  }
}

.sum-tip {
  padding: 12px;

  >.title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
  }

  >.content {
    margin-top: 8px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }
}

.switch_fn_bt {
  margin-right: 20px;
  width: 80px;
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  background-color: #F9F9F9;
  color: #222;
  border: 1px solid #E3E3E6;
  cursor: pointer;
}
</style>